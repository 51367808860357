<script context="module" lang="ts">
	import type {
		BorderRadius,
		BorderRadiusOptions,
		BorderStyle,
	} from '$lib/components/Border.svelte';
	import Border from '$lib/components/Border.svelte';
	import Plane from '$lib/components/Plane.svelte';

	export declare namespace ButtonNamespace {
		export type Props = {
			backgroundColour?: TColours;
			colour?: TColours;
			borderColour?: TColours;
			type?: HTMLButtonElement['type'];
			fullWidth?: boolean;
			fullHeight?: boolean;
			borderStyle?: BorderStyle;
			borderRadius?: BorderRadius;
			disabled?: boolean;
			disabledVisually?: boolean;
			to?: string;
			children: Snippet;
			onclick?: (event: MouseEvent) => void;
			outer?: Snippet;
			border?: Snippet;
			borderRadiusOptions?: BorderRadiusOptions;
			autofocus?: boolean;
			additionalClasses?: string;
			shadow?: ShadowsDP;
			hreflang?: string;
			'data-testid'?: string;
			'aria-current'?:
				| Booleanish
				| 'page'
				| 'step'
				| 'location'
				| 'date'
				| 'time'
				| null
				| undefined;
		};
	}
</script>

<script lang="ts">
	import { page } from '$app/stores';
	import type { TColours } from '$lib/data/colours';
	import { getShadowClass, type ShadowsDP } from '$lib/utilities/getShadowClass';
	import classNames from 'classnames';
	import type { Snippet } from 'svelte';
	import type { Booleanish } from 'svelte/elements';

	const {
		backgroundColour,
		colour = 'secondary-black',
		borderColour,
		type,
		fullWidth,
		fullHeight,
		borderStyle,
		borderRadius,
		disabled,
		disabledVisually,
		to,
		children,
		outer,
		border,
		onclick,
		borderRadiusOptions,
		autofocus,
		additionalClasses,
		shadow,
		hreflang,
		...remainingProps
	}: ButtonNamespace.Props = $props();

	const isCurrentRoute = $derived(to === $page.route.id);

	const classes = $derived(
		classNames(
			'Button',
			{
				'Button--disabled': disabled || (type === 'button' && !to && !onclick),
				'Button--disabledVisually':
					disabledVisually || (type === 'button' && !to && !onclick),
				[`Button--colour-${colour}`]: colour,
				[`Button--backgroundColour-${backgroundColour}`]: backgroundColour,
				[`Button--borderColour-${borderColour ?? colour}`]: borderColour ?? colour,
				'Button--fullWidth': fullWidth,
				'Button--fullHeight': fullHeight,
				'Button--isCurrentRoute': isCurrentRoute,
				[`Button--borderStyle-${borderStyle}`]: borderStyle,
			},
			additionalClasses,
		),
	);

	const borderClasses = $derived(
		classNames({
			[shadow ? getShadowClass(shadow) : '']: shadow,
		}),
	);
</script>

{#snippet buttonContent()}
	<Border
		{borderStyle}
		{fullWidth}
		{fullHeight}
		size={1}
		colour={borderColour || backgroundColour}
		radius={borderRadius}
		additionalClasses={borderClasses}
		{...borderRadiusOptions}
	>
		<Plane {fullWidth} {fullHeight} colour={backgroundColour}>
			{@render children()}
		</Plane>

		{#if border}
			{@render border()}
		{/if}
	</Border>
	<!-- https://svelte-5-preview.vercel.app/docs/snippets#typing-snippets -->

	{#if outer}
		{@render outer()}
	{/if}
{/snippet}

{#if to}
	<a
		{autofocus}
		href={disabled || isCurrentRoute ? null : to}
		{hreflang}
		class={classes}
		{onclick}
		{...remainingProps}
	>
		{@render buttonContent()}
	</a>
{:else}
	<button {autofocus} class={classes} {disabled} {type} {onclick} {...remainingProps}>
		{@render buttonContent()}
	</button>
{/if}

<style lang="scss">
	@import '$lib/styles/colours.scss';

	.Button {
		background-color: transparent;
		position: relative;
		display: block;
		line-height: 1;
		cursor: pointer;
		border: none;
		transition: all 0.2s var(--easing);

		&--fullWidth {
			width: 100%;
		}

		&--fullHeight {
			height: 100%;
		}

		@each $colour in $colours {
			&--colour-#{$colour} {
				color: var(--#{$colour});
			}
		}

		&--disabled {
			pointer-events: none;
		}

		&--disabledVisually,
		&--disabled {
			opacity: 0.6;
		}
	}

	a.Button {
		text-decoration: none;
	}

	@media (prefers-reduced-motion: no-preference) {
		.Button:active {
			transform: scale(0.95);
		}
	}

	@media (prefers-reduced-motion: reduce) {
		.Button:active {
			transform: scale(0.99);
		}
	}
</style>
